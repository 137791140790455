import './App.css';
import Index from "./pages/Index/Index";

function App() {
  return (
    <div className="App">
      <Index/>
    </div>
  );
}

export default App;
