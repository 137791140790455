import React from 'react';
import {Card, Layout} from 'antd';
import {GithubOutlined} from "@ant-design/icons"

const gridStyle = {
    width: '25%',
    textAlign: 'center',
};
const {Content} = Layout;

const ClickHandler = (key) => {
    //window.location.href=key.target.firstElementChild.href;
    window.open(key.target.firstElementChild.href);
}

const MyContent = () => (
    <Content>
        <Card title="网站列表">
            <Card.Grid style={gridStyle} onClick={(key) => ClickHandler(key)}>
                <a href={"http://blog.lql.asia"} target="_blank" rel="noreferrer"> 博客 </a>
            </Card.Grid>
            <Card.Grid style={gridStyle} onClick={(key) => ClickHandler(key)}>
                <GithubOutlined/>
                <a href={"https://github.com/sakuraprogramming"} target="_blank" rel="noreferrer">
                    Githup
                </a>
            </Card.Grid>
            <Card.Grid style={gridStyle} onClick={(key) => ClickHandler(key)}>
                <a href={"http://user.lql.asia"} target="_blank" rel="noreferrer"> 用户中心 </a>
            </Card.Grid>
            <Card.Grid style={gridStyle} onClick={(key) => ClickHandler(key)}>
                <a href={"http://sakura.lql.asia"} target="_blank" rel="noreferrer"> 樱花交友 </a>
            </Card.Grid>
            <Card.Grid style={gridStyle} onClick={(key) => ClickHandler(key)}>
                <a href={"http://bi.lql.asia"} target="_blank" rel="noreferrer"> 智能数据分析 </a>
            </Card.Grid>
            <Card.Grid style={gridStyle} onClick={(key) => ClickHandler(key)}>
                <a href={"http://118.31.117.97:3000/"} target="_blank" rel="noreferrer"> 樱花API开放平台 </a>
            </Card.Grid>
            <Card.Grid hoverable={false} style={gridStyle}>待开发</Card.Grid>
        </Card>
    </Content>
);
export default MyContent;