import React from 'react';
import {Layout} from 'antd';
import './Index.css'
import MyContent from "../MyContent/MyContent";

const {Header, Footer} = Layout;
const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#7dbcea',
};
const Index = () => (
    <Layout style={{minHeight: '100vh'}}>
        <Layout>
            <Header style={headerStyle}>个人测试学习</Header>
            <MyContent/>
            <Footer>
                <div style={{"margin": "0 auto"}}>
                    <a href="https://beian.miit.gov.cn/" target="_blank"
                       rel="noreferrer">备案号:浙ICP备2023031355号-1</a>
                    <span> && </span>
                    <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">公安备案号:</a>
                </div>
            </Footer>
        </Layout>
    </Layout>
);
export default Index;